<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 40px; line-height: 40px">
      <span
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          opacity: 0.5;
          margin-right: 13px;
          cursor: pointer;
        "
        @click="toSearch"
        >项目配置/</span
      >
      <span style="font-size: 14px; font-weight: 400; color: #082254"
        >项目详情</span
      >
    </div>

    <div
      style="
        width: 100%;
        height: calc(100% - 40px);
        background: white;
        padding: 20px;
        position: relative;
      "
    >
      <div style="font-size: 14px; font-weight: 600; color: #082254">
        项目详情
      </div>
      <div
        style="
          width: 200px;
          height: 250px;
          background: #d8d8d8;
          position: absolute;
          top: 60px;
          left: 20px;
        "
      ></div>
      <div
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          position: absolute;
          top: 83px;
          left: 370px;
        "
      >
        项目名称：{{ detailData.projectName }}
      </div>
      <div
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          position: absolute;
          top: 133px;
          left: 370px;
        "
      >
        项目类型：{{ detailData.projectType | formatAType }}
      </div>
      <div
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          position: absolute;
          top: 183px;
          left: 370px;
        "
      >
        项目面积：{{ detailData.projectArea }}m2
      </div>
      <div
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          position: absolute;
          top: 233px;
          left: 370px;
        "
      >
        状态：{{ detailData.enabled | formatStatus }}
      </div>

      <div
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          position: absolute;
          top: 83px;
          left: 688px;
        "
      >
        项目简介：
      </div>
      <div
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          position: absolute;
          top: 83px;
          left: 768px;
          width: 303px;
          min-height: 120px;
        "
      >
        {{ detailData.projectDesc }}
      </div>
      <div style="width: 100%; height: 32px; margin-top: 330px">
        <div
          v-if="!detailData.enabled"
          style="
            width: 200px;
            height: 32px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              height: 32px;
              width: 70px;
              background: #3f6df8;
              border-radius: 2px;
              color: white;
              text-align: center;
              line-height: 32px;
              cursor: pointer;
            "
            @click="changeStatus(detailData.projectId)"
          >
            {{ changeWorc }}
          </div>
          <div
            style="
              height: 30px;
              width: 70px;
              border-radius: 2px;
              border: 1px solid #3f6df8;
              color: #3f6df8;
              text-align: center;
              line-height: 30px;
              cursor: pointer;
            "
            @click="showUpdate(detailData.projectId)"
          >
            修改
          </div>
        </div>
        <div
          v-else
          style="
            height: 32px;
            width: 70px;
            background: #3f6df8;
            border-radius: 2px;
            color: white;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            margin: 0 auto;
          "
          @click="changeStatus(detailData.projectId)"
        >
          {{ changeWorc }}
        </div>
      </div>
    </div>

    <el-dialog
      :title="userSettingTitle"
      :visible.sync="changePasswordVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item
          label="项目编码："
          prop="projectId"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input :disabled="isView" v-model="ruleForm.projectId"></el-input>
        </el-form-item>
        <el-form-item
          label="项目名称："
          prop="projectName"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input
            :disabled="isView"
            v-model="ruleForm.projectName"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="项目类型："
          prop="projectType"
          style="position: relative; margin-bottom: 20px"
        >
          <el-select
            v-model="ruleForm.projectType"
            placeholder="请选择"
            style=""
            clearable
          >
            <el-option
              v-for="item in optionsType2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="项目面积："
          prop="projectArea"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input
            :disabled="isView"
            v-model="ruleForm.projectArea"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="项目简介："
          prop="projectDesc"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input
            :disabled="isView"
            v-model="ruleForm.projectDesc"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="项目组："
          prop="projectGroups"
          style="position: relative; margin-bottom: 20px"
        >
          <el-select
            v-model="ruleForm.projectGroups"
            placeholder="请选择"
            @change="getOption"
            :disabled="isView"
            multiple
          >
            <el-option
              v-for="item in optionsInstance"
              :key="item.dataPrivilegeGroupId"
              :label="item.dataPrivilegeGroupName"
              :value="item.dataPrivilegeGroupId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="上传图片："
          prop="username"
          style="position: relative; margin-bottom: 20px"
        >
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="是否发布："
          prop="enabled"
          style="position: relative; margin-bottom: 10px"
        >
          <el-switch :disabled="isView" v-model="ruleForm.enabled"> </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addNew">确 定</el-button>
        <el-button @click="changePasswordVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import * as echarts from "echarts";
import {
  getProjectSettingDetail,
  updateProjectSetting,
  changeProjectSetting,
  getGroupList,
} from "@/request/apiLg";
export default {
  filters: {
    formatStatus: function (value) {
      if (value) {
        return "发布";
      } else {
        return "暂停";
      }
    },
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    formatAType(value) {
      if (value === "BUSINESS") {
        return "商业用户";
      } else if (value === "ENERGY_STATION") {
        return "能源站";
      } else if (value === "INDUSTRIAL") {
        return "工业用户";
      } else if (value === "POWER_STATION") {
        return "光伏电站";
      } else if (value === "STORAGE_POWER_STATION") {
        return "储能电站";
      } else {
        return "--";
      }
    },
  },
  data() {
    return {
      optionsInstance: [],
      imageUrl: "",
      isView: false,
      updateProjectSetting: "编辑项目",
      changePasswordVisible: false,
      ruleForm: {
        enabled: false,
        projectArea: "",
        projectDesc: "",
        projectGroups: [],
        projectId: "",
        projectName: "",
        projectType: "",
      },
      currentName: "",
      tableKey: [],
      tableKey2: [],
      changeWorc: "发布",
      finishTitle: "",
      loading: false,
      active: 2,
      multipleSelection: [],
      tableData: [],
      value1: "",
      isActivenTime: 1,
      detailData: {},
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      userSettingTitle: "编辑项目",
      rules: {},
      optionsType2: [
        {
          value: "BUSINESS",
          label: "商业用户",
        },
        {
          value: "ENERGY_STATION",
          label: "能源站",
        },
        {
          value: "INDUSTRIAL",
          label: "工业用户",
        },
        {
          value: "POWER_STATION",
          label: "光伏电站",
        },
        {
          value: "STORAGE_POWER_STATION",
          label: "储能电站",
        },
      ],
    };
  },
  methods: {
    getOption() {},
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    showUpdate(id) {
      getProjectSettingDetail(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          // getInstanceAttr(resA.data.data.instanceId).then((res2) => {
          //   // debugger
          //   // this.options2[v] = res2.data.data;
          //   // console.log("this.options2", this.options2);
          //   // let temp = JSON.parse(JSON.stringify(this.options2));
          //   // this.options2 = temp;
          //   this.optionsInstanceAttr = res2.data.data;
          //   this.changePasswordVisible = true;
          //   this.isEdit = true;
          //   this.isView = false;
          //   this.ruleForm = resA.data.data;
          // });
          this.userSettingTitle = "编辑项目";
          this.changePasswordVisible = true;
          // this.isEdit = true;
          // this.isView = false;
          this.ruleForm = resA.data.data;
        }
      });
    },
    changeStatus(id) {
      // debugger
      if (this.detailData.enabled) {
        this.$confirm("确认暂停？")
          .then(() => {
            // done();
            changeProjectSetting(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                // this.searchFor();
                this.changeWorc = "发布";
                getProjectSettingDetail(this.$route.query.id).then((res2) => {
                  // this.loading = false;
                  console.log(res2);
                  this.detailData = res2.data.data;
                  if (this.detailData.enabled) {
                    this.changeWorc = "暂停";
                  } else {
                    this.changeWorc = "发布";
                  }
                  if (!this.detailData.projectDesc) {
                    this.detailData.projectDesc = "--";
                  }
                  if (!this.detailData.projectArea) {
                    this.detailData.projectArea = "--";
                  }
                });
              }
            });
          })
          .catch(() => {});
      } else {
        this.$confirm("确认发布？")
          .then(() => {
            // done();
            changeProjectSetting(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                // this.searchFor();
                this.changeWorc = "暂停";
                getProjectSettingDetail(this.$route.query.id).then((res2) => {
                  // this.loading = false;
                  console.log(res2);
                  this.detailData = res2.data.data;
                  if (this.detailData.enabled) {
                    this.changeWorc = "暂停";
                  } else {
                    this.changeWorc = "发布";
                  }
                  if (!this.detailData.projectDesc) {
                    this.detailData.projectDesc = "--";
                  }
                  if (!this.detailData.projectArea) {
                    this.detailData.projectArea = "--";
                  }
                });
              }
            });
          })
          .catch(() => {});
      }
    },
    addNew() {
      // let temp = {
      //   alarmId: "",
      //   alarmLevelId: "",
      //   alarmLevelName: "",
      //   alarmType: "",
      //   attrId: "",
      //   attrName: "",
      //   instanceId: "",
      //   instanceName: "",
      //   maxDisplay: 0,
      //   minDisplay: 0,
      //   periodMinute: "",
      //   released: true,
      // };

      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // alarmSetCheck(this.ruleForm).then((resC) => {
          // if (!resC.data.data.exist) {
          // if (this.ruleForm.pwd) {
          //   delete this.ruleForm.pwd;
          // }
          // let dateT = new Date();
          // this.ruleForm.updateTime = this.formatDate(dateT);
          updateProjectSetting(this.ruleForm).then((res) => {
            console.log("res", res);
            if (res.data.code === 0) {
              this.changePasswordVisible = false;
              this.$message.success("编辑成功");
              // this.searchFor();
              getProjectSettingDetail(this.$route.query.id).then((res2) => {
                // this.loading = false;
                console.log(res2);
                this.detailData = res2.data.data;
                if (this.detailData.enabled) {
                  this.changeWorc = "暂停";
                } else {
                  this.changeWorc = "发布";
                }
                if (!this.detailData.projectDesc) {
                  this.detailData.projectDesc = "--";
                }
                if (!this.detailData.projectArea) {
                  this.detailData.projectArea = "--";
                }
              });
            }
          });
        }
      });
    },
    toSearch() {
      this.$router.push({ name: "projectSetting" });
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    clickNH(index) {
      this.isActivenTime = index;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  mounted() {
    getGroupList().then((res) => {
      this.optionsInstance = res.data.data;
      // getInstanceAttr(this.optionsInstance[0].instanceId).then((res1) => {
      //   this.optionsInstanceAttr = res1.data.data;
      // });
    });
    // getAlarmDetail(this.$route.query.id).then((res) => {
    //   console.log("res", res);
    //   this.detailData = res.data.data;
    //   if (this.detailData.alarmStatus === "UNTREATED") {
    //     this.active = 0;
    //     this.finishTitle = "已处理";
    //   } else if (this.detailData.alarmStatus === "PROCESSED") {
    //     this.active = 3;
    //     this.finishTitle = "已处理";
    //   } else if (this.detailData.alarmStatus === "INGORED") {
    //     this.active = 3;
    //     this.finishTitle = "已忽略";
    //   }
    // });
    getProjectSettingDetail(this.$route.query.id).then((res2) => {
      // this.loading = false;
      console.log(res2);
      this.detailData = res2.data.data;
      if (this.detailData.enabled) {
        this.changeWorc = "暂停";
      } else {
        this.changeWorc = "发布";
      }
      if (!this.detailData.projectDesc) {
        this.detailData.projectDesc = "--";
      }
      if (!this.detailData.projectArea) {
        this.detailData.projectArea = "--";
      }
      // if(!this.detailData.projectDesc){
      //   this.detailData.projectDesc = '--'
      // }
      // this.tableData = res2.data.data.rows;
      // // this.tableKey = res2.data.data.timestamps;
      // let temp = [];
      // res2.data.data.timestamps.forEach((item) => {
      //   temp.push(this.formatDate(item));
      // });
      // this.tableKey = temp;
      // this.tableKey2 = res2.data.data.columns;
    });
  },
};
</script>
<style>
.el-step__head.is-success {
  color: #1a7aff;
  border-color: #1a7aff;
}
.el-step__title.is-success {
  color: #1a7aff;
}
</style>
<style scoped>
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.title1 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #f6b084 0%, #ea9b7a 46%, #e97f75 100%);
  border-radius: 4px;
  position: relative;
}
.title2 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #71a1de 0%, #5a82dd 46%, #516adc 100%);
  border-radius: 4px;
  position: relative;
}
.title3 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #b97df9 0%, #ad6bf1 54%, #8c44e1 100%);
  border-radius: 4px;
  position: relative;
}
.title4 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #6bcf84 0%, #6ec887 46%, #2aab4c 100%);
  border-radius: 4px;
  position: relative;
}

.body1 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.body2 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style scoped>
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.el-dialog__wrapper >>> input {
  width: 350px !important;
  height: 32px !important;
}

.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
.time-square:hover {
  background: #3f6df8 !important;
  color: white !important;
}
.isSelectTime {
  background: #3f6df8 !important;
  color: white !important;
}
.small-s >>> input {
  width: 220px !important;
  height: 32px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 750px !important;
}
.inline-input >>> input {
  width: 160px !important;
}
.small-input >>> input {
  width: 160px !important;
}
.small-input >>> .el-input {
  width: 160px !important;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>